import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { generateId } from '../helpers/general';
import { VisibilityProperty } from 'csstype';
let visibilityTimeout = null;

const Accordion = ({ headingType, heading, children }) => {
    let content = null;
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(generateId('accordion'));
    const [height, setHeight] = useState(0);
    const [visibility, setVisibility] = useState('hidden');

    const handleToggle = () => {
        clearTimeout(visibilityTimeout);

        if (!open) {
            setOpen(true);
            setVisibility('visible');
            setHeight(content.getBoundingClientRect().height + 30);
        } else {
            visibilityTimeout = setTimeout(() => {
                setVisibility('hidden');
            }, 400);

            setOpen(false);
            setHeight(0);
        }
    };

    const headingButton = (
        <button type="button" aria-expanded={open} aria-controls={id} onClick={handleToggle}>
            {heading}
        </button>
    );

    return (
        <div className="accordion">
            {headingType === 3 ? <h3>{headingButton}</h3> : <h2>{headingButton}</h2>}

            <div
                id={id}
                aria-hidden={!open}
                style={{
                    height,
                }}
            >
                <div
                    ref={element => (content = element)}
                    style={{
                        visibility: visibility as VisibilityProperty,
                    }}
                >
                    {children}
                </div>
            </div>

            <style jsx>{`
                :global(.accordion-group) {
                    margin-top: 20px;

                    & .accordion:last-child {
                        border-bottom: 1px solid rgba(38, 50, 56, 0.2);
                    }
                }

                .accordion {
                    border-top: 1px solid rgba(38, 50, 56, 0.2);

                    & > div {
                        padding: 0 20px 0 0px;
                        box-sizing: border-box;
                        overflow: hidden;
                        transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                        will-change: transform;

                        & > div > :global(:first-child) {
                            margin-top: 10px;
                        }
                    }
                }

                h2,
                h3 {
                    margin-top: 0;

                    & > :global(button) {
                        webkit-appearance: none;
                        background-color: transparent;
                        font-family: Lato, sans-serif;
                        font-weight: bold;
                        text-transform: capitalize;
                        letter-spacing: 1.4px;
                        border: none;
                        border-radius: 0;
                        box-shadow: none;
                        color: #263238;
                        cursor: pointer;
                        display: block;
                        margin: 0;
                        padding: 10px 20px 10px 35px;
                        text-align: left;
                        width: 100%;
                        position: relative;

                        &::after {
                            content: '';
                            background: url('data:image/svg+xml;utf8, <svg width="12" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L7 7L13 1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
');
                            background-position-y: center;
                            background-position-x: center;
                            background-repeat: no-repeat;
                            display: block;
                            width: 20px;
                            height: 20px;
                            border: 1px solid #c2bbba;
                            border-radius: 50%;
                            line-height: 12px;
                            position: absolute;
                            right: 0px;
                            opacity: 0.7;
                            top: 10px;
                            transform-origin: 50% 8px;
                            transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                            will-change: transform;
                        }
                    }

                    & > :global(button[aria-expanded='true']::after) {
                        background: url('data:image/svg+xml;utf8, <svg width="12" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 7L7 1L13 7" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
');
                        background-position-y: center;
                        background-position-x: center;
                        background-repeat: no-repeat;
                        background-color: #f44336;
                        border: 1px solid #f44336;
                        opacity: 100%;
                    }
                }

                h2 {
                    & > :global(button) {
                        line-height: 40px;
                        font-size: 28px;
                    }
                }

                h3 {
                    & > :global(button) {
                        font-size: 16px;
                        line-height: 24px;
                        padding-left: 0;
                    }
                }

                @media print {
                    .accordion > div {
                        height: auto !important;

                        & > div {
                            visibility: visible !important;
                        }
                    }
                }
            `}</style>
        </div>
    );
};

Accordion.propTypes = {
    headingType: PropTypes.number,
    heading: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default Accordion;
