import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import FourWeekPhysiquePage from "../../components/4-week-physique"

const FourWeekPhysique = () => {
    return (
        <Layout noFooter={true} noHeader={true}>
            <SEO
                title="4 Week Physique | J Clarke Fitness"
            />
         <FourWeekPhysiquePage />
        </Layout>
    )
}
export default FourWeekPhysique;