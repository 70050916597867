import React, { useEffect } from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import { NUTRITION_PLAN } from '../../enums/store.enum';
import Accordion from '../../components/accordion';
import { transformationPlanStyle } from '../../styles/transformation-plan-style';
import Logo from '../../images/logo';

const FourWeekPhysiquePage = ({
    heading,
    description_html: description,
    highlight,
    slider_images,
    four_week_physique_included,
    showSpecialOfferSection,
    showOnlyLogo,
    predefinedPrice,
    predefinedCoupon,
    footer,
}) => {
    // hide chat
    useEffect(() => {
        const el = document.getElementById('fb-root');
        if (el) {
            el.classList.remove('d-none');
            return () => {
                el.classList.add('d-none');
            };
        }
    }, []);

    const finalPrice = predefinedPrice ?? NUTRITION_PLAN.price;
    const originalPrice = NUTRITION_PLAN.price;
    const checkoutUrl = predefinedCoupon
        ? `/nutrition-hub/checkout?coupon=${predefinedCoupon}&noheader=true`
        : `/nutrition-hub/checkout?4-week-physique=true&noheader=true`;

    return (
        <>
            <div className="hero">
                {showOnlyLogo && (
                    <div className="checkout_text_style">
                        <Logo />
                    </div>
                )}
                <div>
                    <div className="text">
                        <h1>{heading}</h1>
                        <div dangerouslySetInnerHTML={{ __html: description }} />
                        {highlight && <p className="strong">{highlight}</p>}
                        <div className="shadow-md hero-cta">
                            <div>
                                <div className="price-wrapper">
                                    {predefinedPrice ? (
                                        <>
                                            <p className="strike-price">€{originalPrice}</p>
                                            <p className="price">€{predefinedPrice}</p>
                                        </>
                                    ) : (
                                        <p className="price">€{originalPrice}</p>
                                    )}
                                </div>
                            </div>

                            <Link className="button no-shadow" to={checkoutUrl}>
                                Start now
                            </Link>
                        </div>
                        {/* <NewsletterForm label="Leave your email to get notified as soon as this program is released in September!" /> */}
                    </div>
                    <div className="shadow-lg single-image">
                        <Img fluid={slider_images[0].image.childImageSharp.fluid} />
                    </div>
                </div>
            </div>
            <h2 style={{ textAlign: 'center' }}>WHAT’S INCLUDED</h2>
            <div className="features">
                {four_week_physique_included.map((i, x) => (
                    <div key={x}>
                        <div className="shadow-md image">
                            <Img fluid={i.image.childImageSharp.fluid} alt={i.name} />
                        </div>
                        <div className="text">
                            <h2>{i.heading}</h2>
                            <ul>
                                {i.items.map((e, el) => (
                                    <li
                                        key={el}
                                        dangerouslySetInnerHTML={{ __html: e.text_html }}
                                    />
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>

            <div style={{ margin: '0 24px' }}>
                <div className="shadow-xl footer-cta">
                    <div>
                        <div>
                            <div className="price-wrapper">
                                {predefinedPrice ? (
                                    <>
                                        <p className="strike-price">€{originalPrice}</p>
                                        <p className="price">€{predefinedPrice}</p>
                                    </>
                                ) : (
                                    <p className="price">€{originalPrice}</p>
                                )}
                            </div>
                        </div>

                        <Link className="button no-shadow" to={checkoutUrl}>
                            Start now
                        </Link>
                    </div>
                </div>
            </div>

            <hr className="narrow" />

            <div className="how-it-works">
                <h2>How it works</h2>

                <div>
                    <div style={{ flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ width: '15%', backgroundColor: 'transparent' }}></div>
                        <div className="shadow-xl">
                            <h3>
                                <span>1</span> SIGN UP
                            </h3>
                            <p>Sign up for 4 Week Physique & pay €{finalPrice}.</p>
                        </div>

                        <div className="shadow-xl">
                            <h3>
                                <span>2</span> SET UP YOUR ACCOUNT
                            </h3>
                            <p>
                                You will immediately receive a Welcome Email containing a link to
                                set up your account.
                            </p>
                        </div>

                        <div className="shadow-xl">
                            <h3>
                                <span>3</span> GET STARTED
                            </h3>
                            <p>
                                Get instant access to the Nutrition Hub app, community and your
                                workouts to start your journey.
                            </p>
                        </div>
                        <div style={{ width: '15%', backgroundColor: 'transparent' }}></div>
                    </div>
                </div>
            </div>

            <div>
                <div className="guess-what">
                    <h2>OH AND GUESS WHAT…</h2>
                    <ul>
                        <li>
                            When you sign up to 4 Week Physique, you get access to the Nutrition Hub
                            app AND Community for 12 MONTHS
                        </li>
                        <li>
                            So when the challenge is over, you will still have access to the recipe
                            videos/books, restaurant survival guides, calorie & macro calculator,
                            progress tracker and the community!
                        </li>
                    </ul>
                </div>
                <div style={{ margin: '0 24px' }}>
                    <div className="shadow-xl footer-cta">
                        <div>
                            <div>
                                <div className="price-wrapper">
                                    {predefinedPrice ? (
                                        <>
                                            <p className="strike-price">€{originalPrice}</p>
                                            <p className="price">€{predefinedPrice}</p>
                                        </>
                                    ) : (
                                        <p className="price">€{originalPrice}</p>
                                    )}
                                </div>
                            </div>

                            <Link className="button no-shadow" to={checkoutUrl}>
                                Start now
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="narrow" />

            <div className="wrapper-dark">
                <div className="final-section features">
                    <div className="img-container">
                        <img
                            src={footer.image.childImageSharp.fixed.src}
                            alt="JC Fitness Gym Program"
                        />
                    </div>
                    <div className="content">
                        <div dangerouslySetInnerHTML={{ __html: footer.content_html }} />
                        <Link
                            className="button white no-shadow"
                            to={checkoutUrl}
                            style={{
                                fontSize: '110%',
                                marginTop: 20,
                                padding: '8px 20px',
                                backgroundColor: 'white',
                                color: 'red',
                            }}
                        >
                            Start now
                        </Link>
                    </div>
                </div>
            </div>

            <div className="gutter-container">
                <div>
                    <h2 className="faq-heading">FAQs</h2>

                    <Accordion
                        headingType={3}
                        heading="I'm a picky eater. Will I like the recipes?"
                    >
                        <p>
                            The Nutrition Hub contains a huge number of recipes. So, if you don't
                            like a certain food, no big deal! You will still have lots of options.
                        </p>
                    </Accordion>
                    <Accordion
                        headingType={3}
                        heading="What if I live in another country and cannot find the ingredients?"
                    >
                        <p>
                            This is nothing to be concerned about as there are clear instructions on
                            what to do if you cannot find a specific ingredient. This is not and has
                            never been an issue with anyone who purchases my plans!
                        </p>
                    </Accordion>
                    <Accordion
                        headingType={3}
                        heading="Do you need to have access to a gym for the workouts?"
                    >
                        <p>
                            Yes, the workouts are based around equipment you’ll find in commercial
                            gyms.
                        </p>
                    </Accordion>
                    <Accordion
                        headingType={3}
                        heading="What happens after the 12 month access to the Nutrition Hub and community?"
                    >
                        <p>
                            There is a recurring fee of €49 per year if you’d like continued access.
                            You can cancel before this via the cancel option in your Nutrition Hub
                            account.
                        </p>
                    </Accordion>
                </div>
            </div>
            {/* @ts-expect-error */}
            <style jsx>{transformationPlanStyle}</style>
            {/* @ts-expect-error */}
            <style jsx>
                {`
                    .checkout_text_style {
                        display: flex !important;
                        flex-direction: column;
                        align-items: flex-start !important;
                        background-color: #263238;
                        max-width: unset !important;
                    }
                    .checkout_text_wrapper {
                        max-width: 1180px;
                        width: 100%;
                        margin: 0px auto;
                    }
                    .special_offer_section {
                        color: white;
                    }
                    .special_offer_section p {
                        color: white;
                    }
                    .special_offer_section .red {
                        color: #f44336;
                    }
                    .strike-price {
                        margin: 0px;
                        margin-right: 10px;
                        font-weight: bold;
                        font-size: 20px;
                        text-decoration: line-through;
                    }
                    .price-wrapper {
                        display: flex;
                        align-items: flex-end;
                    }
                    :global(h1) {
                        text-transform: none;
                    }
                    .footer-cta {
                        max-width: 480px;
                        margin: auto;
                        margin-top: 30px;
                        padding: 20px;
                    }
                    .single-image {
                        margin-top: 40px;
                        max-width: 400px;
                        width: 100%;
                    }
                    .guess-what {
                        text-align: center;
                    }
                    .guess-what ul {
                        display: inline-block;
                    }
                    .wrapper-dark {
                        background-color: #eb6059;
                        color: white;

                        & .hero-cta {
                            color: rgba(38, 50, 56, 0.8);
                        }
                    }
                    .final-section {
                        margin: 0 auto;
                        display: flex;
                        align-items: flex-start;
                        & .img-container {
                            flex: 1;
                            margin: 0;
                            margin-right: 30px;
                            display: none;
                            & img {
                                object-fit: cover;
                                width: 100%;
                            }
                        }
                        & .content {
                            flex: 2;
                            display: block;
                            margin-top: 0;
                            & :global(p) {
                                margin-top: 0;
                                margin-bottom: 20px;
                                color: inherit;
                                font-size: 22px;
                            }
                        }
                    }
                    @media (min-width: 650px) {
                        h1 {
                            font-size: 42px;
                        }
                        .final-section {
                            & .img-container {
                                display: block;
                            }
                        }
                    }
                `}
            </style>
        </>
    );
};

const fourWeekPhysiqueQuery = graphql`
    query FourWeekPhysiquePageQuery {
        allMarkdownRemark(filter: { frontmatter: { pageName: { eq: "4-week-physique" } } }) {
            edges {
                node {
                    frontmatter {
                        heading
                        description_html
                        highlight
                        slider_images {
                            image {
                                childImageSharp {
                                    fluid(maxWidth: 500) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                        four_week_physique_included {
                            heading
                            items {
                                text_html
                            }
                            image {
                                childImageSharp {
                                    fluid(maxWidth: 500) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                        footer {
                            content_html
                            image {
                                childImageSharp {
                                    fixed(width: 500) {
                                        src
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default props => (
    <StaticQuery
        query={fourWeekPhysiqueQuery}
        render={({ allMarkdownRemark }) => {
            const [edge] = allMarkdownRemark.edges;
            const pageData = edge.node.frontmatter;
            return <FourWeekPhysiquePage {...pageData} {...props} />;
        }}
    />
);
