import loadFunctions from '../components/firebase-functions';
import firebase from 'firebase/app';

export function gaEvent(action, data) {
    if (process.env.NODE_ENV === 'production') {
        window['gtag']('event', action, { ...data });
    }
}

export const roundTo = (value: number, decimalPlaces: number): number => {
    const manipulator = 10 ** decimalPlaces;

    return Math.round(value * manipulator) / manipulator;
};

export const convertToClassNameString = (classNamesArray: Array<string>): string =>
    classNamesArray.filter((className: string) => className !== '').join(' ');

export const capitalizeString = (value: string): string =>
    value.charAt(0).toUpperCase() + value.slice(1);

export function calculateDiscount(
    price: number,
    coupon: { percentOff?: number; amountOff?: number }
): number {
    const { amountOff, percentOff } = coupon;
    if (percentOff) {
        return Math.round(price * (percentOff / 100));
    } else if (amountOff) {
        return amountOff;
    } else {
        return 0;
    }
}

export function stringifyCurrency(amount: number): string {
    const stringAmount = amount.toString();
    const eurosParsed = stringAmount.slice(0, -2);
    const euros = eurosParsed === '' ? '0' : eurosParsed;
    const cents = stringAmount.slice(-2);

    return cents === '00' || cents === '0' ? euros : `${euros}.${cents}`;
}

export const generateId = (prefix: string): string =>
    `${prefix}-${Math.random()
        .toString(36)
        .substr(2, 5)}`;

export function fbEvent(name: string, data?: any) {
    try {
        if (process.env.NODE_ENV === 'production') {
            window['fbq']('track', name, data);
        }
    } catch (e) {
        console.log(e);
    }
}

let checkCouponCodeFn: firebase.functions.HttpsCallable | null = null;
export async function validateCouponCode(price: number, code: string, email: string) {
    if (!checkCouponCodeFn) {
        const firebaseFunctions = await loadFunctions();
        checkCouponCodeFn = firebaseFunctions.httpsCallable('checkCouponCode');
    }

    try {
        const res = await checkCouponCodeFn({ email, couponCode: code });
        const reducedAmount = calculateDiscount(price, res.data);
        const { durationInMonths } = res.data;

        return {
            code,
            reducedAmount,
            durationInMonths,
            finalAmount: price - reducedAmount,
            error: null,
        };
    } catch (error) {
        return {
            error: error?.message as string,
        };
    }
}
